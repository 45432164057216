<template>
  <component :is="authStore.authStatus === true ? AuthenticatedUI : PublicUI" />
</template>

<script setup>
import {useAuthStore}  from '@/stores/authStore.js';
import AuthenticatedUI from '@/components/pages/AuthenticatedUI.vue';
import PublicUI        from '@/components/pages/PublicUI.vue';

const authStore = useAuthStore();
</script>