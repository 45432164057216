// formatting options for human-readable dates
const date_options = {
  year:   'numeric',
  month:  'short',
  day:    'numeric',
  hour:   'numeric',
  minute: 'numeric'
};

// FIXME: is hardcoding 'en-US' okay?
const date_fmt = new Intl.DateTimeFormat('en-US', date_options);

const FormatDate = function(d){
  const formatted_date = date_fmt.format(new Date(d))
  // split on comma, get [month day, year, time]
  const pieces = formatted_date.toString().split(', ')
  // return the date formatted to only allow linebreak between year and time
  return `<span class="whitespace-nowrap">${pieces[0]}, ${pieces[1]},</span> <span class="whitespace-nowrap">${pieces[2]}</span>`
}

export default FormatDate;