<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
  <!-- ------------------------------------------ -->
  <!-- SecretsType content -->

    <!-- Secrets table -->
    <SecretsTable
      :secrets="secrets"
      :secretType="secret_type"
      :jsonError="authStore.appAlertMessage !== null"
    />

  <!-- end Secrets Content -->
  <!-- ------------------------------------------ -->
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import SecretsTable from '@/components/pages/Secrets/SecretsTable.vue';

import { fetchMessage } from '@/services/fetchers';
import router      from '@/router/routes.js';
import {useAuthStore} from '@/stores/authStore.js';

const authStore = useAuthStore();

// use null as default value for secrets to show spinners, indicating loading
const secrets = ref(null);
const secret_type = ref(null);

onMounted(async () =>{
  secret_type.value = router.currentRoute.value.params.secret_type.toUpperCase().replace('-', '_');

  try {
    const json = await fetchMessage(`public/secrets`);
    // set the local secrets value to an empty array
    secrets.value = [];

    for (let secret of json){
      for (const finding of secret.findings){
        if (finding.finding_type === secret_type.value){
          secrets.value.push(secret);
        }
      }
    }
  }
  catch(error){
    authStore.setGenericFetchError();
  }
});
</script>