<template>
<div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">

  <h2 class="text-2xl leading-6 font-medium text-gray-900">
    Account Details
  </h2>
  <!-- TODO: replace this with client account info -->
  <div class="bg-white my-3 shadow overflow-hidden sm:rounded-lg">
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">SIFTR License</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><span class="text-red-600">[license info to go here]</span></dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">SIFTR License Term</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><span class="text-red-600">[license term to go here]</span></dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">Account Email</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{userEmail}}</dd>
        </div>
      </dl>
    </div>
  </div>

  <p>Please each out to us at <span class="text-red-600">[SIFTR support email]</span> with questions about your license or account.</p>

  <h2 class="text-2xl mt-11 leading-6 font-medium text-gray-900">
    Reset Password
  </h2>
  <div class="bg-white my-3 shadow overflow-hidden sm:rounded-lg">
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <label for="currentPW" class="text-sm font-medium text-gray-500 self-center"> Current Password </label>
          <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              id="currentPW"
              name="currentPW"
              v-model="currentPW"
              type="password"
              required
              class="rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
            />
          </div>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <label for="newPW1" class="text-sm font-medium text-gray-500 self-center"> New Password </label>
          <div class="mt-1 relative text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              id="newPW1"
              name="newPW1"
              v-model="newPW1"
              ref="newPW1ref"
              type="password"
              required
              @input="checkPW(1, $event)"
              class="rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
            />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <CheckCircleIcon v-if="newPW1.length > 0 && validPW[1]" class="h-5 w-5 text-green-600" aria-hidden="true" />
              <ExclamationCircleIcon v-if="newPW1.length > 0 && !validPW[1]" class="h-5 w-5 text-red-500" aria-hidden="true" />
            </div>
          </div>

          <div class="text-gray-700 text-sm sm:col-start-2 sm:col-span-2">
            <p>
              Make sure it's
              <span :class="newPW1.length > 0 ? (lengthOkay(newPW1) ? '' : 'text-red-700 font-semibold') : ''">
                at least 8 characters</span>,

              <span :class="newPW1.length > 0 ? (containsUppercase(newPW1) ? '' : 'text-red-700 font-semibold') : ''">
                uses uppercase</span> and

              <span :class="newPW1.length > 0 ? (containsLowercase(newPW1) ? '' : 'text-red-700 font-semibold') : ''">
                lowercase letters</span>, and

              <span :class="newPW1.length > 0 ? (containsNumbers(newPW1) ? '' : 'text-red-700 font-semibold') : ''">
                has numbers</span> and

              <span :class="newPW1.length > 0 ? (containsSpecialCharacters(newPW1) ? '' : 'text-red-700 font-semibold') : ''">
                special characters</span>.
            </p>
          </div>
        </div>

        <div class="py-4 sm:pt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <label for="newPW2" class="text-sm font-medium text-gray-500 self-center"> Confirm New Password </label>
          <div class="mt-1 relative text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <input
              id="newPW2"
              name="newPW2"
              v-model="newPW2"
              type="password"
              required
              @input="checkPW(2, $event)"
              @keyup.enter="submit"
              class="rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
            />
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon v-if="newPW2.length > 0 && (!validPW[2] || !passwordsMatch)" class="h-5 w-5 text-red-500" aria-hidden="true" />
              <CheckCircleIcon v-if="newPW2.length > 0 && validPW[2] && passwordsMatch" class="h-5 w-5 text-green-600" aria-hidden="true" />
            </div>
          </div>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-start-2 sm:col-span-2">
            <SubmitButton text="Update Password" :showLockIcon="false" :action="submit" />
          </div>
        </div>
      </dl>
    </div>
  </div>
</div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import { useAuthStore } from '@/stores/authStore.js';
import _debounce from 'lodash/debounce'

import SubmitButton from '@/components/_common/SubmitButton.vue';
import { ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/vue/solid'

import { fetchMessage } from '@/services/fetchers';

// ----------------------------------------------
const authStore = useAuthStore();

// for account details
const json = ref(null);
const userEmail = ref('');

// for reset password form
const currentPW = ref('');
const newPW1ref = ref(null)
const newPW1 = ref('');
const newPW2 = ref('');


const validPW = ref([null, null]);
const passwordsMatch = ref(null);

// ----------------------------------------------

const lengthOkay = function(pw){
  return pw.length >= 8;
}
const containsUppercase = function(pw){
  const pattern = new RegExp("^(?=.*[A-Z]).+$");
  return pattern.test(pw);
}
const containsLowercase= function(pw){
  const pattern = new RegExp("^(?=.*[a-z]).+$");
  return pattern.test(pw);
}
const containsNumbers = function(pw){
  const pattern = new RegExp("^(?=.*\\d).+$");
  return pattern.test(pw);
}
const containsSpecialCharacters = function(pw){
  const pattern = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$");
  return pattern.test(pw);
}

const debouncedCheck = _debounce(function (pw, index) {
  validPW.value[index] = lengthOkay(pw)
        && containsUppercase(pw)
        && containsLowercase(pw)
        && containsNumbers(pw)
        && containsSpecialCharacters(pw);

  passwordsMatch.value = (newPW1.value === newPW2.value);
}, 300)

const checkPW = (index, event) => {
  debouncedCheck(event.target.value, index);
}

// ----------------------------------------------
onMounted(async()=>{
  try {
    json.value = await fetchMessage(`public/me`);
    userEmail.value = authStore.userEmail;
  }
  catch(error){
    authStore.setAppMessage(
      'There was an error retrieving your account details; try refreshing the page.  If the problem persists, let us know.',
    );
  }
});


const submit = ()=>{
  if (newPW1.value === '' || newPW2.value === ''){
    authStore.setAppMessage('New password cannot be blank.');
    newPW1ref.value.focus();

  } else if (newPW1.value === newPW2.value){
    // TODO: use Vuelidate for clientside form validation?
    authStore.resetAppMessage()
    const response = authStore.changePassword({ currentPW: currentPW.value, newPW: newPW1.value });

    if (response === false){
      newPW1ref.value.focus();
    } else {
      // visually indicate that password was updated successfully
      authStore.setAppMessage('Your password has been updated.', 'success');
      currentPW.value = null;
      newPW1.value = null;
      newPW2.value = null;
    }
  } else {
    authStore.setAppMessage('Passwords do not match.');
    newPW1ref.value.focus();
  }
}

</script>