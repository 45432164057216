<template>
<div>
  <div>
    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-800">
      Sign in to your account
    </h2>
  </div>

  <div class="mt-8 space-y-6">
    <div class="rounded-md shadow-sm -space-y-px">
      <div>
        <label for="username" class="sr-only">
          Account
        </label>
        <input
          v-model="username"
          id="username"
          name="username"
          type="text"
          v-focus
          placeholder="Username"
          required
          class="appearance-none rounded-none rounded-t-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
        />
      </div>
      <div>
        <label for="password" class="sr-only">Password</label>
        <input
          v-on:keyup.enter="signIn"
          v-model="password"
          id="password"
          name="password"
          type="password"
          autocomplete="current-password"
          required
          class="appearance-none rounded-none rounded-b-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
          placeholder="Password"
        />
      </div>
    </div>

    <div class="flex items-center justify-between">
      <div class="text-sm">
        <router-link to="/forgot-password" class="appearance-none border-none bg-none font-medium rounded-md inline-block text-gray-600 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-500 focus:border-gray-500">
          Forgot your password?
        </router-link>
      </div>
    </div>

    <SubmitButton text="Sign In" :action="signIn" />
  </div>
</div>
</template>

<script setup>
import {ref} from 'vue';
import SubmitButton from '@/components/_common/SubmitButton.vue';
import { useAuthStore } from '@/stores/authStore.js'

const authStore = useAuthStore();

const username = ref('');
const password = ref('');

const signIn = ()=>{
  if (!username.value || username.value === ''){
    authStore.setAppMessage('Username cannot be empty.');
  } else {
    // clear app error
    authStore.resetAppMessage();
    authStore.login(username.value, password.value);
  }
}
</script>