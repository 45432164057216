import axios from 'axios';
import { EXTERNAL_API_URL_BASE } from '@/aws-config.js';

export const fetchMessage = async function (route, args){
  const url = `${EXTERNAL_API_URL_BASE}${route}`
  // see: https://masteringjs.io/tutorials/axios/json
  // for a simple intro to axios.get()
  const response = await axios.get(url, {
    headers: {
      Authorization: localStorage.getItem("JWT")
    },
    params: args
  });

  return response.data;
}

// --------------------------------------------------------

const tryGetFilenameFromUrl = function(url) {
  const idx = url.lastIndexOf('/');
  
  // If index of last slash is found and its position is less than total length of the URL
  if (idx > 0 && url.length > idx) {
    // Return the string after the last slash and remove any thing after ? (query string) or # (location hash)
    return url.substring(idx + 1).replace(/[\#\?].*$/,'');
  }
  
  return null;
}

const downloadAsBlob = async function(url){
  try {
    // Get the contents of the file
    const response = await axios({
      method: 'get',
      url: url,
      responseType: 'blob'
    });
    
    return response.data;
  }
  catch (err) {

  }
}

// This function takes a blob contents and the content type and forces the browser to save
// the contents to a file
const forceSave = async function(blob, contentType, filename){
  // Create a URL using the blob data
  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
  const objUrl = URL.createObjectURL(new Blob([blob], { type: contentType }));

  // Create our anchor element
  // This doesn't actually get attached to the DOM, we just need it to make the browser download the file
  const a = document.createElement('a');

  // Set the href to the object url
  a.href = objUrl;
  
  // The "download" attribute tells the browser to save the file, not load in the browser window
  // It's also used to specify the file name to download the file as
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-download
  a.download = filename || 'download';

  // We're going to create a click callback so that we can clean up afterwards
  const clickHandler = function() {

    setTimeout(() => {
      // Clean up resource
      URL.revokeObjectURL(objUrl);

      // Remove the click event handler itself
      // arrow functions in JavaScript don't have their own "this"
      // in this context, "this" refers to the anonymous clickHandler function
      this.removeEventListener('click', clickHandler);
    }, 100);
  };

  // Add the click event listener on the anchor element
  a.addEventListener('click', clickHandler, false);

  // Click to start the download
  a.click();
}

const contentTypes = {
  'csv':  'text/csv',
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const downloadFinding = async function(findingId, fileExtension){
  const url  = await fetchMessage(`${EXTERNAL_API_URL_BASE}download/${findingId}`);
  const blob = await downloadAsBlob(url);

  if (blob) {
    const contentType = contentTypes[fileExtension];
    const filename =  `${tryGetFilenameFromUrl(url)}.${fileExtension}`;
    forceSave(blob, contentType, filename);
  }
}