import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Amplify from 'aws-amplify';
import PrimeVue from 'primevue/config';

import App     from './App.vue';
import router  from './router/routes.js';


import './index.css';
import './sraPrimeVue.css';

// ------------------------------------------------------
import VueQrcode from '@chenfengyuan/vue-qrcode';

// ------------------------------------------------------
// AWS amplify config

import aws_config from './aws-config.js';


import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_config);

applyPolyfills().then(() => {
  defineCustomElements(window)
});


// ------------------------------------------------------
// Font Awesome config

// import individual FontAwesome icons
// https://fontawesome.com/v5.15/how-to-use/javascript-api/setup/importing-icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faArrowCircleRight, faUser, faSpinner,
  faHome, faQuestion, faInfo, faFileDownload,
  faSort, faSortUp, faSortDown, faExternalLinkAlt,
  faMobileAlt,
  // faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faArrowCircleRight, faUser, faSpinner,
  faHome, faQuestion, faInfo, faFileDownload,
  faSort, faSortUp, faSortDown, faExternalLinkAlt,
  faMobileAlt,
  // faEye, faEyeSlash,
);

// ------------------------------------------------------
// SIFTR frontend as a Vue app

const app = createApp(App);

// ------------------------------------------
// add custom components with scope over the
// entire Vue application

// https://github.com/FortAwesome/vue-fontawesome
// <font-awesome-icon :icon="['fas', 'file-download']" />
app.component('font-awesome-icon', FontAwesomeIcon);

// https://github.com/fengyuanchen/vue-qrcode
// <vue-qrcode value='ABC123DEF' />
app.component(VueQrcode.name, VueQrcode);
// ------------------------------------------

// ------------------------------------------
// Register a global custom directive called `v-focus`
app.directive('focus', {
  // When the bound element is mounted into the DOM...
  mounted(el) {
    // Focus the element
    el.focus()
  }
})
// ------------------------------------------


app.use(router);
app.use(createPinia())
app.use(PrimeVue);

// mount our Vue app to the element with id="app" in the DOM
app.mount('#app');
