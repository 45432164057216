import { defineStore } from 'pinia';

export const useUIStore = defineStore('ui', {
  state:() => ({
    // activeFeature can be a string like '/secrets' or '/breach'
    // we can set it when rendering a specific finding (Finding.vue)
    // and then use that shared state in SidebarItem to highlight
    // the appropriate "parent" SidebarItem for this specific finding
    activeFeature: null,
  })
});