<template>
  <div class="mt-8">

    <SecretsHeader :secretType="secretType" />

    <div class="overflow-x-auto mt-3 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table class="table-auto min-w-full border-collapse">
        <thead>
          <tr
            class="
              border-t border-b border-r border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              uppercase
              text-left text-sm
              tracking-wider
            "
          >
            <th class="px-4 md:px-5 py-3 font-medium">
              <!-- hide "Details" text for xs width, but keep the th element -->
              <span class="hidden sm:table-cell"> Details </span>
            </th>
            <th
              @click="SortTable('SK', direction, secrets)"
              class="px-2 md:px-3 xl:px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Site
              <font-awesome-icon
                :icon="['fas', (direction['SK'] || 'sort')]"
                :class="direction['SK'] !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              />
            </th>

            <th class="px-4 md:px-5 xl:px-6 py-3 font-medium">
              Findings
            </th>

            <th
              @click="SortTable('status', direction, secrets)"
              class="px-4 md:px-5 xl:px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Status
              <font-awesome-icon
                :icon="['fas', (direction.status || 'sort')]"
                :class="direction.status !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              />
            </th>

            <th
              @click="SortTable('last_updated', direction, secrets)"
              class="hidden md:table-cell px-4 md:px-5 xl:px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Updated
              <font-awesome-icon
                :icon="['fas', (direction.last_updated || 'sort')]"
                :class="direction.last_updated !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              />
            </th>
          </tr>
        </thead>
        <tbody
          v-if="secrets !== null && secrets.length > 0"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            v-for="(secret, index) in secrets"
            :key="index"
            class="border-t border-gray-300"
            :class="colors.tr[secret.status]"
          >
            <!-- link to full secrets -->
            <td
              class="border-l-8 text-gray-900"
              :class="colors.td[secret.status]"
            >
              <router-link
                :to="`/secrets/finding/${secret.uid}`"
                class="pl-10 pr-6 py-4 block w-auto h-auto"
              >
                <font-awesome-icon :icon="['fas', 'arrow-circle-right']" />
              </router-link>
            </td>

            <!-- site -->
            <td class="px-2 md:px-3 xl:px-6 pt-4 inline-block items-center smmd:w-72 lg:w-80">
                <div class="truncate self-center">
                  {{ secret.SK }}
                </div>
            </td>

            <!-- finding_type (ad_cred, db_cred, etc.)  -->
            <td class="px-4 md:px-5 xl:px-6 py-3">
              <div class="flex items-center space-x-2">
                <div class="flex flex-shrink-0 space-x-1">
                  <span
                    v-if="secret.findings?.length > 1"
                    class="italic"
                  >
                    (multiple)
                  </span>
                  <span v-else-if="secret.findings?.length === 1">
                    {{ userStrings.SECRETS.finding_type[secret.findings[0].finding_type] }}
                  </span>
                  <span
                    v-else-if="secret.findings?.length === 0"
                  >
                    (none)
                  </span>
                </div>
              </div>
            </td>

            <!-- status -->
            <td class="px-4 md:px-5 xl:px-6 py-3">
              <div class="flex items-center space-x-2">
                <div
                  class="flex flex-shrink-0 space-x-1 font-semibold"
                  :class="colors.text[secret.status]"
                >
                  {{ userStrings.Status[secret.status] }}
                </div>
              </div>
            </td>

            <td class="hidden md:table-cell md:px-5 xl:px-6 py-3">
              <time
                v-html="FormatDate(secret.last_updated)"
                class="tw-text-gray-900 dark:tw-text-gray-100 tw-text-left"
                :datetime="updateTime"
              />
            </td>
          </tr>
        </tbody>
        <tbody
          v-else-if="secrets !== null && secrets.length === 0"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            class="
              border-t border-b border-r border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              text-left text-sm
              tracking-wider
            "
          >
            <td colspan="5" class="px-4 xl:px-6 py-3">(no findings)</td>
          </tr>
        </tbody>
        <tbody
          v-else-if="secrets === null"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            class="
              border-t border-b border-r border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              text-left text-sm
              tracking-wider
            "
          >
            <td colspan="5" class="px-4 xl:px-6 py-3">
              <font-awesome-icon
                :icon="['fas', 'spinner']"
                :class="jsonError === true ? 'text-red-400' : 'text-gray-400'"
                class="text-xl animate-spin-pulse mr-4"
              />
              <span v-if="jsonError !== true" class="text-gray-400">loading...</span>
              <span v-else class="text-red-400">network error</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import userStrings from '@/strings/en.js';
import SortTable from '@/services/TableSort.js';
import FormatDate from '@/services/DateFormat.js';
import SecretsHeader from '@/components/pages/Secrets/SecretsHeader.vue';

const direction = ref({ SK: null, status: null, last_updated: 'sort-down' });

const colors = {
  tr: { NEW: 'bg-red-50', OPEN: 'bg-yellow-50', RESOLVED: 'bg-green-50', },
  td: { NEW: 'border-red-300 hover:bg-red-100', OPEN: 'border-yellow-300 hover:bg-yellow-100', RESOLVED: 'border-green-300 hover:bg-green-100', },
  text: { NEW: 'text-red-800', OPEN: 'text-yellow-800', RESOLVED: 'text-green-800', },
}

const props = defineProps({
  secrets: Array,
  jsonError: Boolean,
  secretType: String,
});

onMounted(()=>{
  // FIXME: I think this isn't working because created()
  // is being called before the table has data to sort.
  // We may need to use async/await.  -dan
  SortTable('last_updated', direction.value, props.secrets);
});
</script>