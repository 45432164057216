<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
  <!-- ------------------------------------------ -->
  <!-- DataBreach content -->

    <!-- Data Breach table -->
    <BreachTable
      :findings="breach_findings"
      :breachAttr="breach_attr"
      :breachVal="breach_val"
      :jsonError="authStore.appAlertMessage !== null"
    />

  <!-- end DataBreach Content -->
  <!-- ------------------------------------------ -->
  </div>
</template>

<script setup>
import {ref, onMounted, watchEffect} from 'vue';
import BreachTable from '@/components/pages/Breach/BreachTable.vue';
import router      from '@/router/routes.js';
import { fetchMessage } from '@/services/fetchers';
import { useAuthStore } from '@/stores/authStore.js';

const authStore = useAuthStore();

// use null as default value for secrets to show spinners, indicating loading
const breach_findings = ref(null);
const breach_attr = ref();
const breach_val  = ref();

const getResponse = async () =>{
  breach_attr.value = router.currentRoute.value.params.breach_attr;
  breach_val.value  = router.currentRoute.value.params.breach_val;

  const request_url = (breach_attr.value && breach_val.value)
      ? `public/breach/${breach_attr.value}/${breach_val.value}`
      :  'public/breach/';

  try {
    const response = await fetchMessage(
      request_url,
      {limit: 100} // temporary hardcoded arg, for demo purposes only
    );
    breach_findings.value = response.items;
  }
  catch(error){
    authStore.setGenericFetchError();
  }
}

// make an API call when the component first mounts,
// and if there are any state (i.e. route) changes later
onMounted(  () => getResponse());
watchEffect(() => getResponse());

</script>