<template>
  <div
    v-if="!dismissed"
    class="rounded-md p-4" 
    :class="`bg-${color || variantColor}-50`"
  >
    <div class="flex">
      
      <div 
        class="flex-shrink-0 mt-px" 
        :class="`text-${color || variantColor}-800`"
      >
        <component :is="icon" />
      </div>

      <div class="ml-3">
         <div
          class="mt-2 text-sm" 
          :class="`text-${color || variantColor}-700`"
        >
          <slot />
        </div>
      </div>
      <div
        v-if="allowDismiss"
        @click="dismissed = true"
        class="ml-auto pl-3 mt-px"
      >
        <div>
          <button
            type="button"
            class="inline-flex rounded-md p-1.5 focus:outline-none"
            :class="[`text-${color || variantColor}-600`, `hover:background-${color || variantColor}-900`]"
          >
            <span class="sr-only">Dismiss</span>
            <XIcon 
              class="h-4 w-4"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { CheckCircleIcon,InformationCircleIcon, ExclamationCircleIcon, XCircleIcon, XIcon } from '@heroicons/vue/solid'

const dismissed = ref(false);

const props = defineProps({
  variant: String,
  color:   String,
  allowDismiss: { 
    type: Boolean,
    default: true
  },
});


const variantColor = computed(() => {
  const colors = {
    success: 'green',
    info:    'blue',
    warning: 'yellow',
    error:   'red',
  };

  return colors[props.variant];
});

const icon = computed(() => {
  const icons = {
    success: CheckCircleIcon,
    info:    InformationCircleIcon,
    warning: ExclamationCircleIcon,
    error:   XCircleIcon,
  };

  return icons[props.variant];
});

</script>