<template>
  <div class="mt-8">
    <h2 class="text-2xl leading-6 font-medium text-gray-900">
      <router-link
        type="a"
        to="/breach"
        class="hover:text-blue-500"
      >Data Breach</router-link>

      /
      <span v-if="breachAttr == null || userStrings.BREACH.breach_attr[breachAttr] == null">
        All Findings
      </span>
      <span v-else>
        {{ userStrings.BREACH.breach_attr[breachAttr] }}
        /
        {{ breachVal }}
      </span>
    </h2>

    <div class="overflow-x-auto mt-3 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <DataTable
        ref="dt"
        :totalRecords="totalRecords"

        :paginator="true"
        :first="lazyParams.first"
        :lazy="true"
        :value="breach_findings"

        :rows="default_num_rows"
        :rowHover="true"

        :loading="loading"
        :globalFilterFields="['email','password','hashed_password','username','name', 'address']"

        @page="onPage($event)"

        class="divide-y sra-dataTable"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        responsiveLayout="scroll"
      >

        <template #empty>
          <span class="ml-6">No Breach data found.</span>
        </template>

        <template #loading>
          <span class="ml-6" :class="jsonError === true ? 'text-red-400' : 'text-gray-400'">
            <font-awesome-icon
                :icon="['fas', 'spinner']"
                class="text-xl animate-spin-pulse mr-2"
              />
            Loading Breach data. Please wait.
          </span>
        </template>


        <Column header="email ">
          <template #body="slotProps">
              <router-link
                type="a"
                class="w-full h-full inline-block indent-10 pt-3 hover:bg-gray-200 hover:cursor-pointer"
                :to="`/breach/by/email/${slotProps.data.email}`"
              >
                {{slotProps.data.email}}
              </router-link>
            </template>
        </Column>
        <Column header="password">
          <template #body="slotProps">
              {{slotProps.data.password}}
          </template>
        </Column>
        <Column header="hashed password">
            <template #body="slotProps">
                {{ slotProps.data.hashed_password }}
            </template>
        </Column>
        <Column header="username">
          <template #body="slotProps">
            <router-link
              type="a"
              v-if="slotProps.data.username.length > 0"
              class="w-full h-full inline-block indent-10 pt-3 hover:bg-slate-200 hover:cursor-pointer"
              :to="`/breach/by/username/${encodeURI(slotProps.data.username)}`"
            >
              {{ slotProps.data.username }}
            </router-link>
          </template>
        </Column>
        <Column header="name">
          <template #body="slotProps">
            <router-link
              type="a"
              class="w-full h-full inline-block indent-10 pt-3 hover:bg-slate-200 hover:cursor-pointer"
              v-if="slotProps.data.name.length > 0"
              :to="`/breach/by/name/${encodeURI(slotProps.data.name)}`"
            >
              {{ slotProps.data.name }}
            </router-link>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup>
import {ref, toRef, onMounted, watch} from 'vue';

import userStrings from '@/strings/en.js';
import { useAuthStore } from '@/stores/authStore.js';
import router      from '@/router/routes.js';
import { fetchMessage } from '@/services/fetchers';

// PrimeVue imports
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import Column from 'primevue/column';
import {FilterMatchMode} from 'primevue/api';

const authStore = useAuthStore();

const props = defineProps({
  findings: Array,
  breachAttr: String,
  breachVal: String,
  jsonError: Boolean
});

// PrimeVue's DataTable uses this breach_findings ref as its data source.
// 1. initialize breach_findings to null
// 2. wait for props.findings to complete from the parent component
//    and set breach_findings to it
// 3. update breach_findings in loadLazyData() as needed
const breach_findings = ref(null);
const propFindingsRef = toRef(props, 'findings');
watch(propFindingsRef, () => breach_findings.value = propFindingsRef.value)

const default_num_rows = 25;
const dt = ref();
const totalRecords = ref(10000);
const loading = ref(false);
const lazyParams = ref({});

onMounted(async () =>{
  loading.value = true;
  const offset = (parseInt(router.currentRoute.value.params.offset)) || 0;

  lazyParams.value = {
    page: offset,
    first: offset * default_num_rows,
    rows: dt.value.rows,
  }

  loadLazyData();
  loading.value = false;
});



const loadLazyData = async(event) => {

  if (event){
    lazyParams.value.page = event.page;
    lazyParams.value.first = event.page * default_num_rows;
  }

  loading.value = true;

  try {
    const response = await fetchMessage('public/breach', {
      limit: default_num_rows,
      start: (lazyParams.value.page + 1)
    });

    breach_findings.value = response.items;

    // TODO: maybe add an API route to quickly get total record count
    // totalRecords.value  = data.totalRecords;
    loading.value = false;
  }
  catch(err){
    authStore.setGenericFetchError();
  }

  loading.value = false;
}


const onPage = (event) => {
  router.push({name: 'BreachOffset', params:{offset: event.page}})
  loadLazyData(event);
};

// const onSort = (event) => {
//   lazyParams.value = event;
//   loadLazyData(event);
// };

// const onSelectAllChange = (event) => {
//   const selectAll = event.checked;

//   if (selectAll) {
//     // customerService.value.getCustomers().then(data => {
//     //   selectAll.value = true;
//     //   selectedCustomers.value = data.customers;
//     // });
//   } else {
//     selectAll.value = false;
//     // selectedCustomers.value = [];
//   }
// }

// const onRowSelect = () => {
//   // selectAll.value = selectedCustomers.value.length === totalRecords.value;
// }

// const onRowUnselect = () => {
//   selectAll.value = false;
// }

</script>