<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <MobileMenu
      @menuToggled="mobileMenuVisible = false"
      :visible="mobileMenuVisible"
    />

    <!-- sidebar for desktop -->
    <Sidebar />

    <!-- main column -->
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <main
        class="flex-1 relative z-0 overflow-y-auto focus:outline-none"
        tabindex="0"
      >
        <!-- Topbar: Search, UserMenu -->
        <TopBar @menuToggled="mobileMenuVisible = true" />

        <SRAAlert
          v-if="authStore.appAlertMessage"
          :variant="authStore.appAlertVariant"
        >
          {{ authStore.appAlertMessage }}
        </SRAAlert>

        <!-- ------------------------------------------ -->
        <!-- Content -->

        <router-view />

        <!-- end Content -->
        <!-- ------------------------------------------ -->

      </main>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import { useAuthStore } from '@/stores/authStore.js';

import MobileMenu from '@/components/_common/MobileMenu.vue';
import TopBar     from '@/components/_common/TopBar.vue';
import Sidebar    from '@/components/_common/Sidebar.vue';
import SRAAlert   from '@/components/_common/SRAAlert.vue';

const authStore = useAuthStore();
const mobileMenuVisible = ref(false)
</script>