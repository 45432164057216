<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
    <!-- ------------------------------------------ -->
    <!-- Breach content -->

    <!-- all Data Breach findings table -->
    <BreachTable
      :findings="breach_findings"
    />

    <!-- end Breach Content -->
    <!-- ------------------------------------------ -->
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import BreachTable from '@/components/pages/Breach/BreachTable.vue';

import router      from '@/router/routes.js';
import { useAuthStore } from '@/stores/authStore.js';
import { fetchMessage } from '@/services/fetchers';

const authStore = useAuthStore();

const offset = ref(0);
const requestLimit = 100;
const breach_findings = ref(null);

onMounted(async () =>{
    offset.value = parseInt(router.currentRoute.value.params.offset) || 0;

    try {
      const response = await fetchMessage('public/breach', {
        limit: requestLimit,
        start: (offset.value + 1)
      });

      breach_findings.value = response.items;
    }
    catch(err){
      authStore.setGenericFetchError();
    }
})
</script>