<template>
<div class="min-h-screen flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
  <div class="max-w-xl w-full space-y-8">
    <SRAAlert
      v-if="authStore.appAlertMessage"
      variant="error"
    >
      {{ authStore.appAlertMessage }}
    </SRAAlert>
    <div class="container mx-auto border-gray-200 pt-2 pb-2 text-white bg-gray-800">
      <img
        class="w-1/3 h-1/3 mx-auto  object-scale-down"
        src="/siftr-logo.png"
        alt="SIFTR logo"
      />
    </div>

    <router-view />
  </div>
</div>
</template>

<script setup>
import { useAuthStore } from '@/stores/authStore.js';
import SRAAlert from '@/components/_common/SRAAlert.vue';
const authStore = useAuthStore();
</script>