<template>
<div>
  <div class="text-center text-gray-800">
    <h2 class="mt-6 text-3xl font-extrabold">
      Reset your password
    </h2>
    <p class="mt-6 text-xl font-bold">
      Enter the verification code sent to your email and provide a new password.
    </p>
  </div>

  <div>
    <form class="mt-8 space-y-6" action="#" method="POST">
      <div class="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="username" class="sr-only">
            Username
          </label>
          <input
            v-model="username"
            v-focus
            id="username"
            name="username"
            type="text"
            placeholder="Username"
            required
            class="appearance-none rounded-none text-gray-900 rounded-t-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 focus:outline-none focus:ring-inset focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
          />
        </div>
        <div>
          <label for="verification-code" class="sr-only">
            Verification Code
          </label>
          <input
            v-model="verificationCode"
            id="verification-code"
            name="verification-code"
            type="text"
            placeholder="Verification Code"
            required
            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-inset focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
          />
        </div>
        <div>
          <label for="password" class="sr-only">
            New Password
          </label>
          <input
            v-model="password"
            id="password"
            name="password"
            type="password"
            placeholder="New Password"
            required
            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-inset focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
          />
        </div>
        <div>
          <label for="password-confirm" class="sr-only">
            Confirm New Password
          </label>
          <input
            v-model="confirmPassword"
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            @keyup.enter="submit"
            placeholder="Confirm New Password"
            required
            class="appearance-none rounded-none rounded-b-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-inset focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
          />
        </div>
      </div>

      <SubmitButton text="Submit" :action="submit" />
    </form>
  </div>
</div>
</template>

<script setup>
import {ref} from 'vue';
import { useAuthStore } from '@/stores/authStore.js';

import { Auth } from 'aws-amplify';
import SubmitButton from '@/components/_common/SubmitButton.vue';

const authStore = useAuthStore();

const username = ref('');
const verificationCode = ref('');
const password = ref('');
const confirmPassword = ref('');

const submit = ()=>{
  if(password.value === confirmPassword.value){

    Auth.forgotPasswordSubmit(username.value, verificationCode.value, confirmPassword.value)
    .then(() => {
      authStore.resetAppMessage();
      authStore.logout();

    }).catch(err => {
      // FIXME: what should we do when forgotPasswordSubmit fails?
      // this can occur when an invalid username or verifcationCode is entered
      //
      // err.message (e.g. 'Password must be at least 8 characters')
      // is already being returned via AWS's 400 status
      // we should either intentionally not leak it at
      // all, or utilize it for user-facing alerts
      authStore.setAppMessage(err?.message || 'The username or verifcation code were invalid.');
    })

  } else {
    authStore.setAppMessage('Passwords do not match.');
    password.focus();
  }
}
</script>
