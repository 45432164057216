<template>
  <div>

  <h2 class="text-2xl leading-6 font-medium text-gray-900">
    Secrets Overview
  </h2>

  <div class="mt-3 gap-5 xl:gap-7 grid grid-cols-1 md:grid-cols-2">

    <Card
      v-if="num_findings.AD_CREDS && num_findings.AD_CREDS.TOTAL > 0"
      title="AD Credentials"
      page="/secrets/ad-creds"
      :newFindings     ="num_findings.AD_CREDS.NEW"
      :openFindings    ="num_findings.AD_CREDS.OPEN"
      :resolvedFindings="num_findings.AD_CREDS.RESOLVED"
      :totalFindings   ="num_findings.AD_CREDS.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="num_findings.DATABASE_CREDS && num_findings.DATABASE_CREDS.TOTAL > 0"
      title="Database Credentials"
      page="/secrets/database-creds"
      :newFindings     ="num_findings.DATABASE_CREDS.NEW"
      :openFindings    ="num_findings.DATABASE_CREDS.OPEN"
      :resolvedFindings="num_findings.DATABASE_CREDS.RESOLVED"
      :totalFindings   ="num_findings.DATABASE_CREDS.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="num_findings.API_KEY && num_findings.API_KEY.TOTAL > 0"
      title="API Keys"
      page="/secrets/api-key"
      :newFindings     ="num_findings.API_KEY.NEW"
      :openFindings    ="num_findings.API_KEY.OPEN"
      :resolvedFindings="num_findings.API_KEY.RESOLVED"
      :totalFindings   ="num_findings.API_KEY.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="num_findings.THIRD_PARTY && num_findings.THIRD_PARTY.TOTAL > 0"
      title="Third Party"
      page="/secrets/third-party"
      :newFindings     ="num_findings.THIRD_PARTY.NEW"
      :openFindings    ="num_findings.THIRD_PARTY.OPEN"
      :resolvedFindings="num_findings.THIRD_PARTY.RESOLVED"
      :totalFindings   ="num_findings.THIRD_PARTY.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="num_findings.OTHER && num_findings.OTHER.TOTAL > 0"
      title="Other"
      page="/secrets/other"
      :newFindings     ="num_findings.OTHER.NEW"
      :openFindings    ="num_findings.OTHER.OPEN"
      :resolvedFindings="num_findings.OTHER.RESOLVED"
      :totalFindings   ="num_findings.OTHER.TOTAL"
      :jsonError       ="jsonError"
    />
  </div>
</div>
</template>

<script setup>
import {ref, toRef} from 'vue';
import Card from '@/components/pages/Secrets/Card.vue';

const props = defineProps({
  num_findings: Object,
  jsonError: Boolean,
});
const num_findings = toRef(props, 'num_findings');
</script>