<template>
  <div class="ml-3 relative">
    <div>
      <button
        type="button"
        class="
          bg-white max-w-xs
          rounded-full flex items-center text-sm
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500
          lg:p-2 lg:rounded-md lg:hover:bg-gray-50
        "
        @click="profileIsOpen = !profileIsOpen"
        id="user-menu"
        aria-expanded="false"
        aria-haspopup="true"
      >

        <font-awesome-icon
          :icon="['fas', 'user']"
          class="text-gray-400 group-hover:text-gray-500 text-center"
        />

        <span class="ml-3 text-gray-700 text-sm font-medium">
          <span class="sr-only">Open user menu for </span>
          {{ userEmail }}
        </span>
        <!-- Heroicon name: solid/chevron-down -->
        <svg
          class="flex-shrink-0 ml-1 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <div
      v-if="profileIsOpen"
      class="
        origin-top-right bg-white
        absolute right-0 mt-2 w-48
        rounded-md shadow-lg
        ring-1 ring-black ring-opacity-5
        focus:outline-none
      "
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="user-menu"
    >
      <router-link
        type="button"
        @click="profileIsOpen = false"
        :to="'/account'"
        class="block w-full text-left rounded-md px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500"
        role="menuitem"
      >
        My Account
      </router-link>
      <button
        type="button"
        @click="signOut"
        class="block w-full text-left rounded-md px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500"
        role="menuitem"
      >
        Sign Out
      </button>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import { useAuthStore } from '@/stores/authStore.js';

const authStore = useAuthStore();
const profileIsOpen = ref(false);
const userEmail = ref(authStore.userEmail);

onMounted(async ()=>{
  userEmail.value = authStore.userEmail;
});

const signOut = async ()=>{
  authStore.logout();
};
</script>