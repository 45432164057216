<template>
<div>
  <div class="text-center">
    <h2 class="mt-6 text-2xl font-extrabold text-gray-800">
      Enter the MFA Code
    </h2>
    <p>
      Open your authenticator app and enter the code below.
    </p>
  </div>

  <div
    class="bg-white shadow-sm overflow-hidden sm:rounded-md text-center mt-6"
  >
    <div class="flex items-center px-4 py-4 sm:px-6">
      <div class="min-w-0 flex-1 flex">
        <div class="min-w-0 flex-1 px-4">
          <div class="my-3 sm:flex sm:items-center">
            <div class="w-full">
              <input
                type="text"
                name="totp"
                id="totp"
                v-focus
                autocomplete="off"
                v-model="totp"
                @keyup.enter="login"
                class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="000000"
              />
            </div>
            <SubmitButton
              text="Login"
              :action="login"
              :showLockIcon="false"
              class="mt-3 sm:mt-0 sm:ml-3 w-full sm:w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import SubmitButton from '@/components/_common/SubmitButton.vue';
import { useAuthStore } from '@/stores/authStore.js';
import router from '@/router/routes.js';

const authStore = useAuthStore();
const totp = ref(null);

onMounted(async () => {
  if (!authStore.user){
    authStore.logout();
    return false;
  }
});

const login = function() {
  if (!totp.value || totp.value === '') {
    authStore.setAppMessage('MFA code cannot be empty.');
    return false;

  } else {
    authStore.resetAppMessage();
    authStore.mfa({
      totp: totp.value,
      mfaType: router.currentRoute.value.params.mfaType,
    });
  }
}

</script>
