const userStrings = {
  SECRETS:{
    feature: 'Secrets',
    source:  'GitHub',
    page:    'secrets',
    finding_type: {
      OTHER:       'Other',
      AD_CREDS:    'AD Credentials',
      API_KEY:     'API Key',
      THIRD_PARTY: 'Third Party',
      DATABASE_CREDS: 'DB Credentials',
    }
  },
  BREACH: {
    feature: 'Data Breach',
    source:  'DeHashed',
    page:    'breach',
    breach_attr: {
      name:     'Name',
      email:    'Email',
      password: 'Password',
      password_hash: 'Password Hash',
    }
  },
  Status: {
    NEW:      'New',
    OPEN:     'Open',
    RESOLVED: 'Resolved',
  },
  Errors: {
    generic_fetch: 'There was an error retrieving your findings; try refreshing the page.  If the problem persists, let us know.'
  }
};

export default userStrings;