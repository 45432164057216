<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
  <!-- ------------------------------------------ -->
  <!-- Secrets content -->

    <!-- Secrets cards -->
    <SecretsOverview
      :num_findings="num_findings"
      :jsonError="authStore.appAlertMessage !== null"
    />

    <!-- Secrets table -->
    <SecretsTable
      :secrets="json"
      :jsonError="authStore.appAlertMessage !== null"
    />

  <!-- end Secrets Content -->
  <!-- ------------------------------------------ -->
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import { useAuthStore } from '@/stores/authStore.js';

import SecretsOverview from '@/components/pages/Secrets/SecretsOverview.vue';
import SecretsTable    from '@/components/pages/Secrets/SecretsTable.vue';

import userStrings      from '@/strings/en.js';
import { fetchMessage } from '@/services/fetchers';

const authStore = useAuthStore();

const num_findings = ref({
  OTHER: {},
  AD_CREDS: {},
  API_KEY: {},
  THIRD_PARTY: {},
  DATABASE_CREDS: {},
});
const json = ref(null);

onMounted(async ()=>{
  try {
    json.value = await fetchMessage(`public/secrets`);

    // initialize these to 0 now, after fetchMessage has returned
    num_findings.value.OTHER          = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
    num_findings.value.AD_CREDS       = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
    num_findings.value.API_KEY        = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
    num_findings.value.THIRD_PARTY    = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
    num_findings.value.DATABASE_CREDS = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};

    for (let secret of json.value){
      for (const finding of secret.findings){
        num_findings.value[finding.finding_type][secret.status] += 1;
        num_findings.value[finding.finding_type].TOTAL += 1;
      }
    }
  }
  catch(error){
    console.log(error)
    authStore.setGenericFetchError();
  }
});
</script>