|<template>
  <h2 class="text-2xl leading-6 font-medium text-gray-900">
    <router-link
      v-if="routeName && routeName !=='Secrets'"
      type="a"
      to="/secrets"
      class="hover:text-blue-500"
    >
    Secrets
    </router-link>
    <span v-else>
      Secrets
    </span>

    /

    <span v-if="routeName === 'Secrets'">
      All Findings
    </span>
    <span v-else-if="routeName === 'SecretsType'">
      {{ userStrings.SECRETS.finding_type[secretType] }}
    </span>
    <span v-else-if="routeName === 'Finding'">
      {{ secretDesc }}
    </span>
  </h2>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import userStrings from '@/strings/en.js';
import router from '@/router/routes.js';

const routeName = ref(null);

onMounted(()=>{
  routeName.value = router.currentRoute.value.name;
});

const props = defineProps({
  secretType: String,
  secretDesc: String,
});
</script>