<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
    <!-- ------------------------------------------ -->
    <!-- Finding content -->

    <SecretsHeader :secretDesc="secret ? secret.description : null" />

    <!-- finding details -->

    <div v-if="secret !== null" class="mt-3">

      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-3xl leading-6 font-medium text-gray-900">
            Details
          </h3>
          <p class="mt-2 max-w-2xl text-md text-gray-500">
            last updated:
            <time
              v-html="FormatDate(secret.last_updated)"
              :datetime="updateTime"
            />
          </p>
        </div>

        <!-- descriptors common to all findings -->
        <div class="border-t border-gray-200 divide-y divide-gray-200">
          <dl class="divide-y divide-gray-200">
            <div
              class="py-4 px-6 grid grid-cols-3 gap-4"
              :class="colors.row[secret.status]"
            >
              <dt class="text-md font-medium text-gray-500">
                Status
              </dt>
              <dd
                class="text-md text-gray-900 sm:col-span-2 font-semibold"
                :class="colors.text[secret.status]"
              >
                {{ userStrings.Status[secret.status] }}
              </dd>
            </div>



            <div class="py-4 px-6 grid grid-cols-3 gap-4">
              <dt class="text-md font-medium text-gray-500">
                Description
              </dt>
              <dd class="text-md text-gray-900 sm:col-span-2">
                {{ secret.description }}
              </dd>
            </div>
          </dl>

          <!-- feature-specific descriptors  -->
          <div v-for="(finding, index) in secret.findings" :key="index">
            <SecretsFinding
              :index="index"
              :finding="finding"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Spinner UI for loading -->
    <div v-else class="mt-3">
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-3xl leading-6 font-medium text-gray-900">
            Finding Details
          </h3>
          <p
            :class="authStore.appAlertMessage !== null ? 'text-red-400' : 'text-gray-400'"
            class="mt-6 max-w-2xl text-md"
          >
            <font-awesome-icon
              :icon="['fas', 'spinner']"
              class="text-xl animate-spin-pulse"
            />
            <span class="ml-4">loading...</span>
          </p>
        </div>
      </div>
    </div>

    <!-- end Finding Content -->
    <!-- ------------------------------------------ -->
  </div>
</template>

<script setup>
import {ref, onMounted}  from 'vue';

import router         from '@/router/routes.js';
import {useUIStore}   from '@/stores/UIStore.js';
import {useAuthStore} from '@/stores/authStore.js';

import SecretsHeader from '@/components/pages/Secrets/SecretsHeader.vue';
import SecretsFinding from '@/components/pages/Secrets/SecretsFinding.vue';

import { fetchMessage } from '@/services/fetchers';
import FormatDate  from '@/services/DateFormat.js';
import userStrings from '@/strings/en.js';

const authStore = useAuthStore();

const secret = ref(null);

const colors = {
  row: { NEW: 'bg-red-50', OPEN: 'bg-yellow-50', RESOLVED: 'bg-green-50', },
  text: { NEW: 'text-red-800', OPEN: 'text-yellow-800', RESOLVED: 'text-green-800', },
}

onMounted(async()=>{
  try {
    secret.value = await fetchMessage(`public/secrets/${router.currentRoute.value.params.id}`);

    // if there are screenshots associated with this secret
    // the `screenshots` key will have an array of filenames
    if (secret.value.screenshots.length > 0){

      // the filename, however, is not enough to fetch the screenshot from AWS
      // so, SIFTR's API route for screenshots takes the secret id
      // and returns its own array of filenames-with-AWS-credentials-appended
      // THESE are what will let us request screenshots from AWS.
      const screenshot_urls = await fetchMessage(`public/screenshots/${router.currentRoute.value.params.id}`);

      // we'll want to pass the full filename-with-creds to the SecretsFinding component
      // by adding a new `screenshot` key to the relevant finding.
      // So, do that by matching the short filename within the longer string. (Overkill?)
      for (let i=0; i < screenshot_urls.length; i++){
        if (screenshot_urls[i].includes(secret.value.screenshots[i])){
          secret.value.findings[i].screenshot = screenshot_urls[i];

        } else {
          for (let j=0; j < secret.value.screenshots.length; j++){
            if (screenshot_urls[i].includes(secret.value.screenshots[j])){
              secret.value.findings[j].screenshot = screenshot_urls[i];
              break;
            }
          }
        }
      }
    }
  }
  catch (error) {
    authStore.setGenericFetchError();
  }
});
</script>