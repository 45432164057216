<template>
<div>
  <div>
    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-800">
      Set your password
    </h2>
    <p class="mt-6 text-center text-xl font-bold text-gray-800">
      Please set a password for your account.
    </p>
  </div>

  <div class="mt-8 space-y-6">
    <div>
      <div class="shadow-sm -space-y-px">
        <label for="username" class="sr-only">Account username</label>
        <input
          :value="username"
          id="username"
          name="uername"
          type="text"
          required
          disabled
          class="appearance-none rounded-none rounded-t-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 disabled:bg-gray-50 disabled:text-gray-500 disabled:border-gray-200 focus:z-10 sm:text-sm"
          placeholder="Account username"
        />
      </div>

      <div class="shadow-sm -space-y-px">
        <label for="currentPW" class="sr-only">Current password</label>
        <input
          @keyup.enter="submit"
          v-model="currentPW"
          id="currentPW"
          name="currentPW"
          type="password"
          required
          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
          placeholder="Current password"
        />
      </div>

      <div class="shadow-sm -space-y-px">
        <label for="new-password1" class="sr-only">New password</label>
        <input
          @keyup.enter="submit"
          v-model="newPW1"
          id="new-password1"
          name="newPW1"
          type="password"
          required
          class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
          placeholder="New password"
        />
      </div>

      <div class="shadow-sm -space-y-px">
        <label for="new-password2" class="sr-only">Confirm New password</label>
        <input
          @keyup.enter="submit"
          v-model="newPW2"
          id="new-password2"
          name="newPW2"
          type="password"
          required
          class="appearance-none rounded-none rounded-b-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm"
          placeholder="Confirm new password"
        />
      </div>
    </div>

    <SubmitButton text="Submit" :action="submit" />
  </div>
</div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import { Auth } from 'aws-amplify';
import SubmitButton from '@/components/_common/SubmitButton.vue';
import { useAuthStore } from '@/stores/authStore.js';

const authStore = useAuthStore();

// username (client email, really) is set during onMounted()
// and displayed in disabled input
const username = ref('');
const currentPW = ref('');
const newPW1 = ref('');
const newPW2 = ref('');

onMounted(async ()=>{
  if (authStore.loginStatus !== 'NEW_PASSWORD_REQUIRED'){
    authStore.logout();
    return false;
  } else{
    username.value = authStore.userEmail;
  }
});

const submit = ()=>{
  Auth.signIn(authStore.userEmail, currentPW.value)
    .then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        if (newPW1.value === newPW2.value){
            Auth.completeNewPassword(user, newPW1.value)
            .then(user => {
              // at this time the user is logged in if no MFA required
              // but we don't want that, so immediately log them out
              authStore.resetAppMessage();
              authStore.logout();

            }).catch(e => {
              if (e.code === "InvalidPasswordException"){
                authStore.setAppMessage(e.message);
              }
            });
        } else {
          authStore.setAppMessage('Please ensure New password matches Confirm new password.');
        }
      }
    }).catch(e => {

    });
  }
</script>