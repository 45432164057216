import { defineStore } from 'pinia';
import { Auth }    from 'aws-amplify';
import router      from '@/router/routes.js';
import userStrings from '@/strings/en.js';

export const useAuthStore = defineStore('auth', {

  state: () => ({
    user: null,
    userEmail: null,
    authStatus: false,
    loginStatus: null,

    appAlertMessage: null,
    appAlertVariant: null,
  }),


  actions: {

    // reset state using `$reset`
    clearAuth () {
      this.$reset()
    },

    async login( userEmail, password ) {
      this.loginStatus = null;
      this.resetAppMessage();

      try {
        const user = await Auth.signIn(userEmail, password);

        // MFA is required for the user to complete login.
        if (user.challengeName === 'SMS_MFA'
        ||  user.challengeName === 'SOFTWARE_TOKEN_MFA'){
          // we'll need the entire `user` object later, in mfa()
          this.user = user;
          this.loginStatus = user.challengeName;
          router.push({ name: 'MFA', params: { mfaType: user.challengeName }});
        }

        // The user is logging in for the first time and needs to set
        // a password. This challengeName should only be returned once
        // ever, immediately following first login.
        else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          // retain the user's email address in state so they don't
          // need to manually re-enter it in the form at /new-password
          this.userEmail   = user.challengeParam.userAttributes.email;
          this.loginStatus = user.challengeName;
          this.authStatus  = false;
          router.push({ name: 'NewPW' });
        }
        // The user needs to set up TOTP before using it.
        else if (user.challengeName === 'MFA_SETUP') {
          this.loginStatus = 'MFA_SETUP';
          this.userEmail = user.challengeParam.userAttributes.email;

          Auth.setupTOTP(user).then((code) =>{
            router.push({ name: 'MFASetup', params: { code: code, userEmail: userEmail, password: password }});
          });

        // We shouldn't ever get here. Punt to logout(), which will
        // revoke auth, set store values to null, and rediret to /login
        } else {
          await this.logout();
          return false
        }
        return true;

      // we end up in the catch block if Auth.signIn() fails
      } catch (err) {
        if (err) {
          // FIXME: Are there other errors we should handle here?
          //        User-doesn't-exist is now handled by cognito, and
          //        returns "CUSTOM_AUTH is not enabled for the client"
          if (err.message === 'Incorrect username or password.') {
            this.setAppMessage( err.message );
          }
          // silently swallow other errors, I guess  -dan
        }
        return false;
      }
    },

    //----------------------------------------------------------
    // checkAuthStatus is called before EVERY route change

    async checkAuthStatus() {

      // ------------------------------------------------------
      // Some loginStatus strings are special-cases for now!
      // In each of these cases, return early from checkAuthStatus
      // because state's value for user will have already been
      // set, but attempting to proceed to Auth.currentSession()
      // will fail.
      //
      // Auth.currentSession() will return false in these cases
      // but we need to retain the user object from login()
      // so the user can attempt MFA.  We don't want to let the user
      // through, but we also don't want fully revoke auth and reset
      // state yet.
      if (this.loginStatus === 'MFA_SETUP'
      ||  this.loginStatus === 'SMS_MFA'
      ||  this.loginStatus === 'SOFTWARE_TOKEN_MFA'
      // Auth.currentSession() would also return false when
      // a user has logged in for the first time, and
      // Cognito deems that they need to set their pw.
      // In this case, the user's email addresss has already
      // been set in state in the `userEmail` field.
      ||  this.loginStatus === 'NEW_PASSWORD_REQUIRED'
      ){
        this.authStatus = false;
        return false;
      }
      // end special-case loginStatus handling
      // ------------------------------------------------------

      try {
        // Auth.currentSession() retrieves user auth data from localStorage
        // it checks Access token and ID token for validity first
        // if those are valid, the user stays signed in
        // if those aren't valid, then it checks the refresh token
        // if refresh token is still valid,  Auth.currentSession() will refresh the Access and ID tokens
        // if refresh token is no longer valid, Auth.currentSession() will fail and return false
        //     TODO: how can I learn more about all this^?  what is the
        //           refresh token? what are the Access and ID tokens?   -dan
        const session = await Auth.currentSession();

        // https://medium.com/@dantasfiles/three-methods-to-get-user-information-in-aws-amplify-authentication-e4e39e658c33
        const user = await Auth.currentAuthenticatedUser();

        const token = user.getSignInUserSession().getIdToken().getJwtToken();
        //prepend JWT from AWS with "Bearer " to conform with FastAPI's HttpBearer
        localStorage.setItem('JWT', "Bearer " + token);

        this.authStatus = true;
        this.userEmail = user.attributes.email;

        return true;

      } catch (err) {
        await this.revokeAuth();
        return false;
      }
    },

    //----------------------------------------------------------
    // revoking AWS auth and setting store values to null needs to be distinct
    // from logout() so that the catch block in checkAuthStatus can handle errors
    // without causing infinite page refresh
    async revokeAuth() {
      // Auth.signOut() should be called when localStorage tokens expire
      // or no session can be found; call it to revoke Cognito token
      await Auth.signOut();
      // remove JWT from localStorage
      localStorage.removeItem('JWT');
      // reset pinia store values
      this.clearAuth();
    },

    async logout() {
      await this.revokeAuth();
      router.push({ name: 'Login' });
    },

    //----------------------------------------------------------
    // changePassword() can be invoked from the /account page
    // TODO: remove console.log() calls
    async changePassword(params){
      const user = await Auth.currentAuthenticatedUser();

      try{
        const response = await Auth.changePassword(user, params.currentPW, params.newPW);
        return true;

      } catch (err) {

        if (err.name === 'InvalidPasswordException'  // newPW didn't meet policy requirements
        ||  err.name === 'NotAuthorizedException'    // currentPW was incorrect
        ){
          // TODO: what are the properties of the err object?
          //       is there a better way to generate this user-facing message?
          const message = err.message.replace(/^\w+Exception: /, '');
          await this.setAppMessage( message );
          return false

        } else if (err.name === 'LimitExceededException'){
          await this.setAppMessage('Password change attempt limit exceeded; please try again later.');
          return false
        }
      }
    },

    //----------------------------------------------------------
    // activateTOTP() should be used once, when a user is
    // setting up their MFA authenticator app for the first time
    // call it like:
    //   authStore.activateTOTP({ totp: userTotpValue });
    async activateTOTP(params){
      try {
        await Auth.verifyTotpToken(this.user, params.totp)
        await Auth.setPreferredMFA(this.user, 'TOTP');
        router.push({ name: 'Secrets' });

      } catch (err) {
        await this.setAppMessage('Invalid code.');
        return false;
      };
    },

    //----------------------------------------------------------
    // mfa() should be used for MFA logins
    // for SMS,  this is every login.  SMS is not currently supported in SIFTR.
    // for TOTP, this is every login after the initial setup (see: activateTOTP).
    // call it like:
    //   authStore.mfa({ totp: userTotpValue, mfaType: userMfaType });
    async mfa(params){
      try {
        await Auth.confirmSignIn(
            this.user,    // object returned from Auth.signIn()
            params.totp,   // Confirmation code
            params.mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
        );

        // MFA succeeded; remove any special loginStatus values now.
        this.loginStatus = null;
        router.push({ name: 'Login' });
        return true;

      } catch (err) {
        await this.setAppMessage('Invalid code.');
        return false;
      }
    },

    //----------------------------------------------------------
    // setAppMessage and resetAppMessage are for user-facing notifications
    // e.g. "The username or verifcation code were invalid."

    // Call authStore.setAppMessage('oh no') to set
    // an error message of "oh no" in the SRAAlert component.
    // The 2nd argument is an alert `variant` string and optional,
    // and set to be 'error' by default.
    // Example usage: authStore.setAppMessage('yay!', 'success')
    async setAppMessage(msg, variant='error'){
      this.appAlertMessage = msg;
      this.appAlertVariant = variant;
    },

    // authStore.setGenericFetchError() can be called from most catch blocks when an
    // API request fails for any reason
    async setGenericFetchError(){
      this.appAlertMessage = userStrings.Errors.generic_fetch;
      this.appAlertVariant = 'error';
    },

    // call authStore.resetAppMessage() to reset
    // the store's alertMessage and alertVariant back to null
    // effectively hiding the SRAAlert component
    async resetAppMessage(){
      this.appAlertMessage = null;
      this.appAlertVariant = null;
    },
  }
});
